import { fetchGet, fetchPost, fetchPostPic } from '@/API'
import axios from 'axios'
import type { AxiosResponse } from 'axios'

export const getOtrStatus = (): Promise<string[]> => {
  return fetchGet('/claimapi/otr/list/status')
}

export const getOtrList = (params: object): Promise<any> => {
  return fetchPost('/claimapi/otr/listPage', params)
}

export const otrExport = (params: object): Promise<AxiosResponse<Blob>> => {
  return axios.post('/claimapi/otr/export', params, {
    responseType: "blob"
  })
}

export const getPackageNameList = (params: object): Promise<string[]> => {
  return fetchPost('/claimapi/otr/list/packageName', params)
}

export const getProgramCodeList = (params: object): Promise<string[]> => {
  return fetchPost('/claimapi/otr/program/programCode', params)
}

export const otrProgramCodeMapping = (params: object) => {
  return fetchPost('/claimapi/otr/programCode/Mapping', params)
}

export const otrStartSystemClaim = (params: object): Promise<{code: string; data: Record<'pass'|'total'|'failed', number>}> => {
  return fetchPostPic('/claimapi/otr/start/system/claim', params)
}