
import { defineComponent, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import useModel from '@/hooks/useModel'
import { debounce } from 'lodash-es'
import { message } from 'ant-design-vue'

import {
  getProgramCodeList as getProgramCodeListAPI,
  otrStartSystemClaim as otrStartSystemClaimAPI
} from '@/API/claim/claimSsscOrder'
export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'confirm'
  ],
  setup(props, { emit }) {
    const visibleModel = useModel(props, 'visible')
    const { getters: {isDealer}, state } = useStore()

    const programCode = ref('')

    const programCodeSelectorState = reactive({
      data: [] as {value: string; label: string}[],
      fetching: false,
    })

    const confirmLoading = ref(false)

    const handleFetchProgramCode = debounce(async (value: string) => {
      programCodeSelectorState.data = (await getProgramCodeListAPI({
        buIds: isDealer ? [] : state.user.buList.map((item: any) => item.id),
        bus: isDealer ? [] : state.user.buList.map((item: any) => item.nameEn),
        dealerCode: isDealer ? state.user.organization.entityCode : '',
        packageName: '',
        programCode: value,
        type: isDealer ? '1' : '2'
      })).map(item => {
        return {
          value: item,
          label: item
        }
      })
    }, 300)

    const handleCancel = () => {
      visibleModel.value = false
      programCode.value = ''
      programCodeSelectorState.data = []
    }

    const resultInfoDialogVisible = ref(false)
    const resultInfo = reactive({
      programCode: '',
      total: 0,
      success: 0,
      fail: 0
    })

    const handleConfirm = async () => {
      confirmLoading.value = true
      try {
        const result = await otrStartSystemClaimAPI({
          programCode: programCode.value,
        })
        if (result.code === '0') {
          resultInfo.programCode = programCode.value
          resultInfo.total = result.data.total
          resultInfo.success = result.data.pass
          resultInfo.fail = result.data.failed
          resultInfoDialogVisible.value = true
          emit('confirm')
        }
      } finally {
        confirmLoading.value = false
      }
      handleCancel()
    }

    const handleOk = () => {
      resultInfoDialogVisible.value = false
    }

    return {
      visibleModel,
      programCode,
      programCodeSelectorState,
      confirmLoading,
      handleFetchProgramCode,
      handleCancel,
      handleConfirm,
      resultInfoDialogVisible,
      resultInfo,
      handleOk
    }
  }
})
