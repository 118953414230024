import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49c329c1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "local-registration-container mapping-container" }
const _hoisted_2 = { class: "mapping-search-panel" }
const _hoisted_3 = { class: "condition-item" }
const _hoisted_4 = { class: "condition-item" }
const _hoisted_5 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createBlock(_component_a_drawer, {
    title: _ctx.$t('ssscOrder.programCodeMapping'),
    width: "500",
    visible: _ctx.visibleModel,
    "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.visibleModel) = $event)),
    onClose: _ctx.handleCancel
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('ssscOrder.packageName')), 1),
            _createVNode(_component_a_select, {
              value: _ctx.packageName,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.packageName) = $event)),
              "show-search": "",
              "show-arrow": false,
              "filter-option": false,
              "not-found-content": _ctx.packageNameSelectorState.fetching ? undefined : null,
              options: _ctx.packageNameSelectorState.data,
              onSearch: _ctx.handleFetchPackageName
            }, _createSlots({ _: 2 }, [
              (_ctx.packageNameSelectorState.fetching)
                ? {
                    name: "notFoundContent",
                    fn: _withCtx(() => [
                      _createVNode(_component_a_spin, { size: "small" })
                    ])
                  }
                : undefined
            ]), 1032, ["value", "not-found-content", "options", "onSearch"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('ssscOrder.programCode')), 1),
            _createVNode(_component_a_select, {
              value: _ctx.programCode,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.programCode) = $event)),
              "show-search": "",
              "show-arrow": false,
              "filter-option": false,
              "not-found-content": _ctx.programCodeSelectorState.fetching ? undefined : null,
              options: _ctx.programCodeSelectorState.data,
              onSearch: _ctx.handleFetchProgramCode
            }, _createSlots({ _: 2 }, [
              (_ctx.programCodeSelectorState.fetching)
                ? {
                    name: "notFoundContent",
                    fn: _withCtx(() => [
                      _createVNode(_component_a_spin, { size: "small" })
                    ])
                  }
                : undefined
            ]), 1032, ["value", "not-found-content", "options", "onSearch"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_a_button, {
          onClick: _ctx.handleCancel,
          class: "m-r-16"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('ssscOrder.cancel')), 1)
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_a_button, {
          type: "primary",
          onClick: _ctx.handleConfirm
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('ssscOrder.confirm')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["title", "visible", "onClose"]))
}