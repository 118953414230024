
import { defineComponent, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import useModel from '@/hooks/useModel'
import { debounce } from 'lodash-es'
import { message } from 'ant-design-vue'

import {
  getPackageNameList as getPackageNameListAPI,
  getProgramCodeList as getProgramCodeListAPI,
  otrProgramCodeMapping as otrProgramCodeMappingAPI
} from '@/API/claim/claimSsscOrder'
export default defineComponent({
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'confirm'
  ],
  setup(props, { emit }) {
    const visibleModel = useModel(props, 'visible')
    const { getters: {isDealer}, state } = useStore()

    const packageName = ref('')
    const programCode = ref('')

    const packageNameSelectorState = reactive({
      data: [] as {value: string; label: string}[],
      fetching: false,
    })

    const programCodeSelectorState = reactive({
      data: [] as {value: string; label: string}[],
      fetching: false,
    })

    const handleFetchPackageName = debounce(async (value: string) => {
      packageNameSelectorState.data = (await getPackageNameListAPI({
        buIds: isDealer ? [] : state.user.buList.map((item: any) => item.id),
        bus: isDealer ? [] : state.user.buList.map((item: any) => item.nameEn),
        dealerCode: isDealer ? state.user.organization.entityCode : '',
        packageName: value,
        programCode: '',
        type: isDealer ? '1' : '2'
      })).map(item => {
        return {
          value: item,
          label: item
        }
      })
    }, 300)

    const handleFetchProgramCode = debounce(async (value: string) => {
      programCodeSelectorState.data = (await getProgramCodeListAPI({
        buIds: isDealer ? [] : state.user.buList.map((item: any) => item.id),
        bus: isDealer ? [] : state.user.buList.map((item: any) => item.nameEn),
        dealerCode: isDealer ? state.user.organization.entityCode : '',
        packageName: '',
        programCode: value,
        type: isDealer ? '1' : '2'
      })).map(item => {
        return {
          value: item,
          label: item
        }
      })
    }, 300)

    const handleCancel = () => {
      visibleModel.value = false
      packageName.value = ''
      programCode.value = ''
      packageNameSelectorState.data = []
      programCodeSelectorState.data = []
    }

    const handleConfirm = async () => {
      await otrProgramCodeMappingAPI({
        buIds: isDealer ? [] : state.user.buList.map((item: any) => item.id),
        bus: isDealer ? [] : state.user.buList.map((item: any) => item.nameEn),
        dealerCode: isDealer ? state.user.organization.entityCode : '',
        packageName: packageName.value,
        programCode: programCode.value,
        type: isDealer ? '1' : '2'
      })
      message.success('Successfully')
      emit('confirm')
      handleCancel()
    }

    return {
      visibleModel,
      packageName,
      programCode,
      packageNameSelectorState,
      programCodeSelectorState,
      handleFetchPackageName,
      handleFetchProgramCode,
      handleCancel,
      handleConfirm
    }
  }
})
